<template>
  <CommonError
    errorMessage="404 页面找不到了"
    :fontAwesomeIcon="['far', 'frown']"
  />
</template>

<script>
import CommonError from "@/components/common/CommonError.vue";
export default {
  name: "Page404",
  components:{CommonError}
};
</script>
<style></style>
